@import url("https://fonts.googleapis.com/css?family=Roboto");

html,
#root,
.App {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

/* 
  wrappers - makes footer sticky work in I.E.
*/
.client-body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.client-header,
.client-footer {
  flex-shrink: 0;
}

.client-content {
  flex: 1 0 auto;
}

.header-image {
  min-width: 100%;
}

/* forms */
.error {
  border: 0.5px solid red;
}

.error_text {
  color: red;
}

.error-container {
  min-height: 100vh;
  background-color: #4190d8;
  color: white;
}

.error-content {
  margin: auto;
}

.error-pgh {
  margin: 3rem;
}

label {
  transition: all 150ms ease-in;
  color: #676767;
}

label.field-active {
  background: inherit;
  transform: translateY(1.2rem) translateX(0.45rem);
  font-size: 0.9em;
  color: #676767;
  text-shadow: 1px 0 0 #fff, -1px 0 0 #fff, 2px 0 0 #fff, -2px 0 0 #fff,
    0 1px 0 #fff, 0 -1px 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff;
}

.input-active {
  padding-top: 2px;
  padding-bottom: 1.5px;
}

/* text */
.headingOne {
  margin: 32px 0 24px;
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
  text-align: center;
}

.roboto {
  font-family: "Roboto", sans-serif;
}

.light-grey-text {
  color: #5b6770;
}

.dark-grey-text {
  color: 465056;
}

/* misc */

#noAccessPage {
  margin: 75px;
  text-align: center;
}

.formatLogo {
  padding: 0.75rem;
}

.hide {
  display: none;
}

.bottom-shadow {
  -webkit-box-shadow: 0 1px 10px 0 #656f77;
  -moz-box-shadow: 0 1px 10px 0 #656f77;
  box-shadow: 0 1px 10px 0 #656f77;
}

.bottom-shadow-card {
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  flex: 1;
  border: none;
}

.client-body-default {
  color: #5b6770;
  background-color: #dddddd;
  min-height: "100vh";
}

.header-default {
  background-color: #565d63;
  color: white;
  height: 100px;
}

.footer-default {
  background-color: #9a9a9a;
  color: white;
  bottom: 0;
  width: 100%;
  min-height: 100px;
}

.card-sidepanel,
.card-mainpanel {
  padding: 0px;
}

.card-sidepanel {
  background-color: grey;
}

.icon {
  color: #5b6770;
  margin-top: 2rem;
}

.form-error {
  max-width: 450px;
  margin: 0.5rem 0;
}

.load-spinner {
  position: fixed;
  z-index: 999;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.notfound-container {
  min-height: 100vh;
  background-color: #99adbc;
}

.notfound-container h2.h3 {
  color: #65737d;
}

/** React bootstrap table style overrides */
/** Table horizontal scroll **/
body .enhancedResultsTable {
  overflow: hidden;
}

body .react-bootstrap-table,
body .react-bootstrap-table-pagination {
  min-width: auto;
}
/** -------------------- **/

body .react-bootstrap-table {
  font-size: 12px;
}

body .react-bootstrap-table .table thead tr th {
  background: #418fde;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  color: #fff;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

body .react-bootstrap-table .table thead tr th:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
}

body .react-bootstrap-table-pagination {
  margin-bottom: 40px;
}

body .react-bootstrap-table-pagination div {
  margin-bottom: 15px;
}

body .react-bootstrap-table-pagination-list .page-item.disabled {
  opacity: 0.5;
  cursor: default;
}

#apple-pass-link img {
  width: 120px;
}

.gpay-pass-link img {
  width: 260px;
}

.card-bal,
.card-eov,
.trans-time,
.card-bal-val,
.card-eov-val,
.balance-history {
  font-size: 1.75rem;
  margin-top: 0px;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}

.card-bal,
.card-eov,
.trans-time {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}

.card-bal-val,
.card-eov-val {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  color: #007bff;
}

.d-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.d-col {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

#cardNoDiv {
  order: 1;
  flex-basis: 100%;
  margin-right: 1%;
}

#pinDiv {
  order: 2;
  flex-basis: 100%;
  margin-top: 1%;
  margin-right: 1%;
}

#captchaDiv.cap-normal {
  order: 3;
  margin-top: 4%;
  flex-basis: 100%;
}

#sbtnDiv {
  order: 4;
  align-items: center;
  margin-top: 4%;
  margin-right: 1%;
  margin-left: auto;
  flex-basis: 100%;
}

#results {
  flex-basis: 100%;
  margin-top: 1.5rem;
}

#balance {
  width: 300px;
}

#balanceRow1,
#balanceRow2,
#balanceRow3,
#balanceRow4 {
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  align-items: center;
}

#balanceRow3,
#balanceRow4 {
  justify-content: space-around;
  margin-top: 2em;
}

#balanceRow1 > div:nth-child(1),
#balanceRow2 > div:nth-child(1) {
  flex-grow: 1;
}

#balanceRow1 > div:nth-child(2),
#balanceRow2 > div:nth-child(2) {
  flex-grow: 1;
  text-align: right;
}

.enhancedResultsTable div.dropup #dropdown-button-drop-up,
.enhancedResultsTable div.dropup #dropdown-button-drop-up:focus {
  box-shadow: unset;
}

:invalid {
  box-shadow: none;
}

:-moz-submit-invalid {
  box-shadow: none;
}

:-moz-ui-invalid {
  box-shadow: none;
}

.wallet-btn {
  font-size: 11px;
  line-height: 1.2;
  text-decoration: none;
  padding: 4px 6px 4px 6px;
  border-top: 1px solid #cccccc;
  border-right: 1px solid #333333;
  border-bottom: 1px solid #333333;
  border-left: 1px solid #cccccc;
  width: 120px;
  height: 37px;
  border-radius: 5px;
  background-color: black;
  color: white;
}

.wallet-btn > i {
  margin: auto 0;
}

.add-to {
  font-size: 9px;
}

#mobilelink:hover,
#mobilelink:focus {
  text-decoration: none;
  cursor: pointer;
}

/* media queries */
/* small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .formatLogo {
    padding: 0.75rem;
  }
}

/* medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .d-col {
    flex: 1;
  }
  .formatLogo {
    padding: 1rem 0 1rem 6rem;
  }
  body .react-bootstrap-table-pagination-list ul {
    float: right;
  }
}

/* large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .formatLogo {
    padding: 1rem 0 1rem 6rem;
  }
  body .react-bootstrap-table-pagination {
    margin-bottom: 0px;
  }
}

/* extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .formatLogo {
    padding: 1rem 0 1rem 6rem;
  }
}

/* extra, extra large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
  .formatLogo {
    padding: 1rem 0 1rem 6rem;
  }
}
